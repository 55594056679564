import Q from "q";
import _ from "underscore";
import is_mobile from "nllib/src/ua-helper/is_mobile";
import wait_some_seconds from "nllib/src/common/wait_some_seconds";
import LoginCore from "yinzhilv-js-sdk/frontend/common-project/component/haolv/login-core/1.0.0/index.vue";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import consumer_sys_sms_vcodeSend from "@/lib/data-service/haolv-default/consumer_sys_sms_vcodeSend";
import consumer_web_company_companyRegiste from "@/lib/data-service/haolv-default/consumer_web_company_companyRegiste";
import consumer_user_userNameIsExist from "@/lib/data-service/haolv-default/consumer_user_userNameIsExist";
import Footer from "@/component/back-layout/1.1.5/component/footer/1.0.2/index.vue";
import DownlodApp from "@/component/download-app/index.vue";

export default {
    components: {
        LoginCore,
        DownlodApp,
        Footer,
    },
    data() {
        const validatePhone = (rule, value, callback) => {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            if (regExp.test(value)) {
                callback();
            } else {
                callback(new Error("不符合手机号码格式"));
            }
        };
        const validatePassword = (rule, value, callback) => {
            const regExp = /[A-Za-z0-9]{6,20}$/;
            const regExp2 = /[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/;
            const regExp3 = /^[0-9a-zA-Z]+$/;
            if (!regExp.test(value)) {
                callback(new Error("密码为6-20长度的字母和数字的组合"));
                return;
            }
            if (!regExp3.test(value)) {
                callback(new Error("密码为6-20长度的字母和数字的组合"));
                return;
            }
            if (!regExp2.test(value)) {
                callback(new Error("密码为6-20长度的字母和数字的组合"));
            } else {
                callback();
            }
        };
        const validatePasswordAgain = (rule, value, callback) => {
            if (value === this.params.password) {
                callback()
            } else {
                callback(new Error('与密码不一致'))
            }
        };
        const validateCompanyName = (rule, value, callback) => {
            const regExp = /[\u2E80-\u9FFFa-zA-Z0-9].{1,30}/;
            if (!regExp.test(value)) {
                callback("不符合公司名格式");
            } else {
                callback();
            }
        };
        return {
            loading: "",
            showLogin: true,
            activeName: 'homePage',
            bannerUrl:[
                'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/20/banner01.png?x-oss-process=style/quancheng-default',
                'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/20/banner02.png?x-oss-process=style/quancheng-default',
                'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/20/banner03.png?x-oss-process=style/quancheng-default',
                'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/20/banner04.png?x-oss-process=style/quancheng-default',
            ],
            pointTabel:[
                {
                    name: '管理者',
                    title: 'Controller',
                    url: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/11/23/people01.png',
                    data: [
                        '员工差旅费用存在虚报',
                        '对于公司差旅各项费用不清楚',
                        '现有供应商票价不透明，差旅费用找不到节省的方法'
                    ]
                },
                {
                    name: '财务官',
                    title: 'Financial officer',
                    url: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/11/23/people02.png',
                    data: [
                        '没有详细的差旅费用报告，需要手动输入',
                        '员工大量的后续报销，需要手工核对票据，工作量大',
                    ]
                },
                {
                    name: '行政员',
                    title: 'Administrator',
                    url: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/11/23/people03.png',
                    data: [
                        '预定工作量大',
                        '经常占用非工作时间',
                        '沟通时间成本高且繁琐'
                    ]
                },
                {
                    name: '员工',
                    title: 'Staff',
                    url: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/11/23/people04.png',
                    data: [
                        '报销流程繁琐',
                        '发票有问题被财务退回',
                        '每月需要垫付大量出差资金'
                    ]
                },
            ],
            caseData:[
                {
                    name:'奥美森智能装备股份有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company10.svg',
                },
                {
                    name:'木林森股份有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company02.svg',
                },
                {
                    name:'广东金城金素制药有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company03.svg',
                },
                {
                    name:'深圳市华讯方舟光电技术有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company04.svg',
                },
                {
                    name:'棕榈控股集团有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company05.svg',
                },
                {
                    name:'广东喜玛拉雅氢能科技有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company06.svg',
                },
                {
                    name:'中山市健康基地集团有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company07.svg',
                },
                // {
                //     name:'中智药业集团',
                //     url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company08.svg',
                // },
                {
                    name:'中山康方生物医药有限公司',
                    url:'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/10/25/company09.svg',
                },


            ],
            params: {
                companyName: "",
                password: "",
                passwordAgain: '',
                phone: "",
                vcode: "",
            },
            rule: {
                companyName: [
                    {required: true, message: "请输入公司", trigger: "blur"},
                    {validator: validateCompanyName, trigger: "blur"},
                ],
                phone: [
                    {required: true, message: "请输入手机号码", trigger: "blur"},
                    {validator: validatePhone, trigger: "blur"},
                ],
                password: [
                    {required: true, message: "请输入密码", trigger: "blur"},
                    {validator: validatePassword, trigger: "blur"},
                ],
                passwordAgain: [
                    {required: true, message: "请再次输入密码", trigger: "blur"},
                    {validator: validatePasswordAgain, trigger: "blur"},
                ],
                vcode: [{required: true, message: "请输入验证码", trigger: "blur"}],
            },
            canGetCode: true,
            codeBtnText: "获取验证码",
            getCodeTime: 60,
            codeInterval: "",
            companyName: "",
            userNameIsExist: consumer_user_userNameIsExist,
            setTime:null,
            aLoginCore: {
                loading: false,
            },
            tableClickOff: 0,
            privacyVisible: false,
            protocolUser: false,
        };
    },
    methods: {
        clickRight(data){
            this.tableClickOff = data;
        },
        handleClick(tab, event){
          this.$router.push({
            name:'login-tabel',
            query:{
              type: tab.$parent.currentName,
            }
          });
        },
        ImgMouseover(num) {
            this.setTime = setTimeout(() => {
                this.$refs.carousel.setActiveItem(num);
            },300);
        },
        ImgMouseout(){
            clearTimeout(this.setTime);
        },
        showRegister() {
            this.showLogin = false;
        },
        toLogin() {
            this.showLogin = true;
        },
        getVCode() {
            if (!this.canGetCode) {
                return;
            }
            if (this.params.phone === "") {
                this.$message({
                    message: "请先输入手机号码",
                    type: "warning",
                });
                return;
            }
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            if (!regExp.test(this.params.phone)) {
                this.$message({
                    message: "不符合手机号码格式",
                    type: "warning",
                });
                return;
            }
            this.canGetCode = false;

            let params = {
                phone: this.params.phone,
                sence: "ALIYUN_VCODE_REGISTER",
            };
            consumer_sys_sms_vcodeSend(params)
                .then((res) => {
                    this.$message({
                        message: "发送成功",
                        type: "success",
                    });
                    this.codeBtnText = `${this.getCodeTime}秒后重新获取`;
                    this.codeInterval = setInterval(() => {
                        this.getCodeTime--;
                        this.codeBtnText = `${this.getCodeTime}秒后重新获取`;
                        if (this.getCodeTime <= 0) {
                            clearInterval(this.codeInterval);
                            this.codeBtnText = "获取验证码";
                            this.getCodeTime = 60;
                            this.canGetCode = true;
                        }
                    }, 1000);
                })
                .catch((res) => {
                    this.canGetCode = true;
                });
        },
        goRegister() {
            this.$refs["registerForm"].validate((valid) => {
                if (valid) {
                    this.loading = this.$loading({
                        lock: true,
                        text: "Loading",
                        spinner: "el-icon-loading",
                    });
                    consumer_web_company_companyRegiste(this.params)
                        .then((result) => {
                            this.loading.close();
                            this.$message({
                                message: "注册成功，请等待审核",
                                type: "success",
                            });
                            this.setTimeout(() => {
                                this.$router.push({
                                    name: "register",
                                    query: {
                                        companyId: result.datas,
                                        companyName: this.params.companyName,
                                        register: 1,
                                    },
                                });
                            },1000);
                        })
                        .catch(() => {
                            this.loading.close();
                        });
                }
            });
        },
        _goPolicy(type) {
            if (type.privacyVisible != null) {
                this.privacyVisible = true;
            }
            if (type.protocolUser != null) {
                this.protocolUser = true;
            }
        }
    },
    created() {
        const __this = this;

        const ua = global.navigator.userAgent;
        const result_is_mobile = is_mobile({ua});
        if (result_is_mobile) {
            __this.$router.replace({
                name: `app-download`,
            });
            return;
        }

        const query = `query {
  allPosts{
    id,
    title,
    content,
    postType{
      id,
      code,
      text,
    }
    createdAtFormat,
    updatedAtFormat,
  },
}`;
    },
    mounted() {
        const __this = this;
        __this.$refs.aLoginCore.init({
            login_successfully_event_handler(data) {
                const environment = global.appConfig.environment;

                __this.aLoginCore.loading = true;
                Q.when()
                // .then(function () {
                //     return wait_some_seconds({
                //         seconds: 10,
                //     });
                // })
                // .then(function () {
                //     return Q.reject();
                // })
                    .then(function () {
                        return get_user_info({
                            clear_cache: true,
                        });
                    })
                    .then(function (data_get_user_info) {

                        //账户类型：1-平台用户，2-客户，3-客户员工
                        const userType = data_get_user_info.datas.userType;
                        //平台账号强制跳转到登录页面
                        if (userType === 1) {
                            alert(`平台账号不应该在此系统登录！`);
                            redirect_to_login_page();
                            return;
                        }

                        //审核状态：0-暂存草稿箱 1-待审核 2-启用 3-审核失败 4-停用
                        const companyStatus = data_get_user_info.datas.tusCompany.companyStatus;

                        const companyId = data_get_user_info.datas.tusCompany.id;

                        //0-暂存草稿箱
                        if (companyStatus === 0) {
                            __this.$router.push({
                                name: `register`,
                                query: {
                                    companyId: companyId,
                                    disabled: 0,
                                }
                            });
                            return;
                        }
                        //1-待审核
                        //3-审核失败
                        if (_.contains([1, 3], companyStatus)) {
                            __this.$router.push({
                                name: `register`,
                                query: {
                                    companyId: companyId,
                                    disabled: 1,
                                }
                            });
                            return;
                        }

                        //2-启用
                        if (companyStatus === 2) {
                            __this.$router.push({
                                name: `admin-travel-book-home`,
                            });
                            return;
                        }

                        //4-停用
                        if (companyStatus === 4) {
                            //理论上不可能会进入到这里
                            alert(`公司状态为停用状态的用户，不能在此系统登录！`);
                            redirect_to_login_page();
                            return;
                        }
                    })
                    .catch(function (ex) {
                        __this.aLoginCore.loading = false;

                        if (ex) {
                            const enable_log_error = !ex.disable_log_error;
                            if (enable_log_error) {
                                console.error(ex);
                                console.trace();
                                if (ex.stack) {
                                    console.error(ex.stack);
                                }
                            }
                        }
                        return Q.reject(ex);
                    })
                ;
            },
        });
    },
    activated() {
        const __this = this;

        __this.form.userName = ``;
        __this.form.userPassword = ``;
    },
};
